//const { func } = require("prop-types");

$(document).ready(function() {

    var select = $('select[multiple]');
    var options = select.find('option'); 
    var div = $('<div />').addClass('selectMultiple');
    var active = $('<div />');
    var list = $('<ul />');
    var placeholder = select.data('placeholder'); 
    var span = $('<span />').text(placeholder).appendTo(active);

    options.each(function() {
        var text = $(this).text();
        if($(this).is(':selected')) {
            active.append($('<a />').html('<em>' + text + '</em><i></i>'));
            span.addClass('hide');
        } else {
            list.append($('<li />').html(text));
        }
    });

    active.append($('<div />').addClass('arrow'));
    div.append(active).append(list); 
    select.wrap(div);

    $(document).on('click', '.selectMultiple ul li', function(e) {
        var select = $(this).parent().parent();
        var li = $(this);
        if(!select.hasClass('clicked')) {
            select.addClass('clicked');
            li.prev().addClass('beforeRemove');
            li.next().addClass('afterRemove');
            li.addClass('remove');
            var a = $('<a />').addClass('notShown').html('<em>' + li.text() + '</em><i></i>').hide().appendTo(select.children('div'));
            a.slideDown(400, function() {
                setTimeout(function() {
                    a.addClass('shown');
                    select.children('div').children('span').addClass('hide');
                    select.find('option:contains(' + li.text() + ')').prop('selected', true);
                }, 500);
            });
            setTimeout(function() {
                if(li.prev().is(':last-child')) {
                    li.prev().removeClass('beforeRemove');
                }
                if(li.next().is(':first-child')) {
                    li.next().removeClass('afterRemove');
                }
                setTimeout(function() {
                    li.prev().removeClass('beforeRemove');
                    li.next().removeClass('afterRemove');
                }, 200);

                li.slideUp(400, function() {
                    li.remove();
                    select.removeClass('clicked');
                });
            }, 600);
        }
    });

    $(document).on('click', '.selectMultiple > div .arrow, .selectMultiple > div span', function(e) {
        $(this).parent().parent().toggleClass('open');
    });

    // $(document).on('click', '.addNew', function(e){
    //     var newItem = $('.newItem').val()
    //      $('.selectMultiple ul').append(`<li>${newItem}</li>`);
    //      $('.newItem').val('')

    // })  

    $(document).on('click', '.addNew', function(e){
        var nwItem = $(this).parents('.dynamicGroup').find('.newItem').val();
        var appenEl = $(this).parents('.dynamicGroup').find('.selectMultiple > div');
        $('<a />').addClass('shown').html('<em>' + nwItem + '</em><i></i>').appendTo(appenEl);
        appenEl.find('span').addClass('hide');
        $(this).parents('.dynamicGroup').find('.newItem').val('');
    });

    $(document).on('click', '.selectMultiple > div > a > i', function(e){
        $(this).parent('a').remove();
    })

});